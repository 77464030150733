.topnav {
  display: flex;
  justify-content: space-around;
  top: 0;
  width: 100%;
  opacity: 0.98;
  z-index: 1;
}

.nav-link {
  font-weight: bold;
  text-align: center;
  padding: 1.3em;
  transition: all 0.4s linear;
  color: var(--col-wgr);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.7em;
  letter-spacing: 0.3em;
  flex: 1;
}

.nav-link:hover {
  background: var(--col-violet-soft);
}

.header-logo {
  width: 50px;
  height: 50px;
  border-radius: 3em;
  padding: 0.4em;
  margin-left: 2em;
}
.nav-link_1 {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--col-whr);
}

button.navbar_toggle {
  display: none;
  user-select: none;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 17%;
  padding-top: 0.5em;
  -webkit-animation: spin 3s linear infinite; /* Safari */
  animation: spin 11s linear infinite;
}

.arrow-down {
  display: none;
  font-size: 3.3em;
  text-align: center;
  opacity: 0.98;
  color: #126e12;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.show-arrow {
  display: block;
}

@media screen and (max-width: 850px) {
  .topnav {
    padding: 1.5em;
  }

  .nav-link {
    display: none;
  }

  .topnav.responsive {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0;
    height: 100vh;
  }

  .topnav.responsive a.nav-link {
    width: 100%;
    margin-top: 3em;
    border-bottom: 1px solid var(--col-wgr);
  }

  button.navbar_toggle {
    display: block;
  }

  .topnav.responsive a {
    display: inline-block;
  }
}
