.main-gy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin-top: 67px;
  padding-bottom: 2em;
}

.gy-container {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  padding: 2.5em;
  justify-content: center;
  gap: 1em;
  /* margin: 1.5em; */
}

.gy-item {
  width: 14rem;
  border-radius: 0.4em;
  cursor: pointer;
  transition: all 0.5s linear;
}

.gy-item:hover {
  filter: blur(0.3em);
}

.gy-img {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 0.4em;
  height: 100%;
}

@media screen and (max-width: 650px) {
  .main-gy-container {
    padding-bottom: 3em;
  }
  .gy-container {
    width: 100%;
  }

  .gy-item {
    width: 41%;
  }
}
