.logo_button_two {
  width: 14em;
  height: 14em;
  background: url("https://res.cloudinary.com/dr8pwzxzn/image/upload/v1734726732/reinasanarte_skcj22.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.action {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 1em auto;
}

#call {
  background-color: var(--col-violet-soft);
  color: var(--col-wgr);
  border-radius: 0.3em;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  cursor: pointer;
  padding: 1em;
  height: 4em;
  outline: none;
  border: 1px solid #36473e;
}

#call:hover {
  background-color: var(--col-violet-dark);
  opacity: .6;
}

#call a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  text-decoration: none;
  color: var(--col-wgr);
}

#call a svg {
  color: var(--col-wgr);
  font-size: 2em;
}
