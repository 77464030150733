#Materiales {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.separator_line {
  width: 85%;
  height: 0.3em;
  background: var(--col-violet-dark);
  border: none;
}

.ul_style {
  display: none;
  background-color: var(--col-violet-dark);
  list-style-image: url("../../public/favicon.ico");
  width: 100%;
  letter-spacing: 0.3em;
  text-shadow: 1px 2px 1px #000;
  transition: all 2s linear;
}

.ul_style_show {
  display: block;
  transition: all 2s linear;
  border-radius: 0.3em;
}

ul li {
  background-color: var(--col-violet-soft);
  padding: 0.5em;
  font-weight: bold;
  list-style-position: inside;
  margin: 1.1em;
  border-radius: 0.3em;
}

.list_title {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--col-violet-dark);
  border-radius: 0.3em;
  width: 100%;
  cursor: pointer;
}

h4 {
  text-align: center;
  letter-spacing: 0.3em;
  margin: 0.7em;
}

p {
  width: 75%;
}

.list_title svg {
  font-size: 1.5em;
}

.list-box {
  display: flex;  
  flex-direction: column;
  align-items: center;
  width: 85%;
  padding: 1em;
}

@media screen and (max-width: 858px) {
  /* .separator_line,
  .ul_style {
    width: 85%;
  } */

  .gy-list {
    margin: 0.7em auto;
  }
}
