.logo_button {
  width: 14em;
  height: 14em;
  background-color: hsla(150, 68%, 38%, 0.301);
  background: url("https://res.cloudinary.com/dr8pwzxzn/image/upload/v1734726731/reinamomo_fnpqhk.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
  align-items: center;
  margin: 0 auto;
  height: 100vh;
}

.container h1 {
  font-weight: 300;
  text-align: center;
  width: 100%;
  font-size: 1.5em;
  text-shadow: 0.2em 0.2em 0.19em #444;
  letter-spacing: 0.3em;
}
