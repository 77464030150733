@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap");

:root {
  color-scheme: light only;
  --col-wgr: #ebeeeb;
  --col-wvr: #bfafc4;
  --col-grn: #082015;
  --col-violet-soft: #8105c991;
  --col-violet-dark: #360752;
  --col-modal-bgr: rgba(39, 2, 63, 0.829);
  --col-scrollbar-track: hsla(279, 70%, 4%, 0.81);
  --col-scrollbar-color: hsla(220 67% 4% 0.81 / 1) hsla(194, 66%, 44%, 0.782);
}

::-webkit-scrollbar {
  width: 0.7em;
}

::-webkit-scrollbar-track {
  background: var(--col-scrollbar-track);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb {
  background: var(--col-violet-dark);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: hsl(281, 19%, 38%);
  cursor: grab;
}

@supports (scrollbar-color: red blue) {
  * {
    scrollbar-color: var(--col-scrollbar-color);
  }
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body,
p,
div,
h1,
h2,
h3,
h4,
a,
span {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

body {
  width: 100%;
  height: auto;
  font-size: 15px;
  font-family: "Nanum Gothic", sans-serif;
  color: var(--col-wgr);
  background-color: #010503;
}

@media screen and (max-device-width: 650px) {
  body {
    -webkit-text-size-adjust: none;
  }
}
