.App {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  opacity: 0.9;
}

h1,
h2,
p {
  font-size: 1.2em;
  text-align: center;
  line-height: 1.2rem;
  padding: 0.5em;
  letter-spacing: 0.2em;
  text-shadow: 2px 1px var(--col-grn);
}

p {
  font-size: 0.9em;
}

footer,
.topnav {
  background: var(--col-violet-dark);
  position: fixed;
}

@media screen and (max-width: 858px) {
  .action_one p {
    padding: 0.5em 2em;
    margin: 0.3em 0;
    width: 85%;
  }

  .container h1 {
    font-size: 1.3em;
  }

  section {
    margin: 0.6em auto;
  }  
}