footer {
  width: 100%;
  bottom: 0;
}

.smedia {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 0.5em;
}

.smedia a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  text-decoration: none;
  color: var(--col-wgr);
  font-weight: lighter;
}

.smedia a svg {
  color: var(--col-wgr);
  font-size: 1.3em;
}