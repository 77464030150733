.img-modal {
  display: none;
}

.img-modal-open {
  display: flex;
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  height: 100%;
  overflow: auto;
  background-color: #080108f5;
  width: 100%;
  align-items: center;
  justify-content: center;
  touch-action: none;
}

.img-container {
  flex: 4;
}

img.modal-content {
  border-radius: .3em;
  display: block;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  object-fit: scale-down;
}

.prev,
.next {
  background-color: initial;
  border: none;
  color: var(--col-whp);
  cursor: pointer;
  flex: 1 1;
  font-size: 2.5em;
  outline: none;
}

.prev {
  flex: 1;
}

.close-modal {
  position: absolute;
  top: 3%;
  font-weight: bold;
  right: 7%;
  font-size: 1.5em;
  cursor: pointer;
  color: var(--col-whp);
  background-color: transparent;
  border: none;
  filter: drop-shadow(0 0 2em var(--col-whr));
}

.close-modal:hover {
  color: var(--col-violet-soft);
  transition: 0.3s;
}

.right-buttons {
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;
}

.prev:hover,
.next:hover {
  color: var(--col-whp);
  color: var(--col-violet-soft);
}

@media screen and (max-width: 650px) {

  .img-modal-open {
    align-items: flex-end;
  }

  .img-container {
    flex: 11;
  }

  .prev,
  .next {
    font-size: 2em;
  }

  .close-modal {
    right: 2%;
    top: 11%;
  }
}